<template>
  <div id="app">
    <!-- 全局返回导航 -->
    <!--<van-sticky>-->
    <!--  <back-nav v-if="showBackNav" />-->
    <!--</van-sticky>-->

    <router-view v-if="!$route.meta.keepAlive" />
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
  </div>
</template>

<script>
// import backNav from "@/components/backNav.vue"

export default {
  name: "App",
  components: {
    // backNav
  },
  data() {
    return {
      showBackNav: false
    }
  },
  watch: {
    // 监听路由对象变化
    $route(to, from) {
      // 根据路由判断是否显示返回导航
      // this.showBackNav = from.meta.isBack ? from.meta.isBack : true
    }
  },
  mounted() {
  },
  methods: {}
}
</script>

<style>
.container {
  box-sizing: border-box;
}

.van-empty {
  font-size: 1rem;
}

.van-toast {
  z-index: 9999 !important;
}

.no-data {
  text-align: center;
  padding: 0.2rem 0;
}
</style>
